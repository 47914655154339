import React, { Component } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from '../Image';
import Pagination from '../Pagination';
import { decodeEntities, getExcerpt } from '../../utils/htmlParse';
import { getUrlVars } from '../../utils/helpers';
// import '../Posts/PostList.scss';
import './CaseStudiesList.scss';

const ListItem = ({ item }) => {
  const { allCaseStudyServices } = useStaticQuery(graphql`
    {
      allCaseStudyServices: allWordpressWpCaseStudyService {
        nodes {
          name
          wordpress_id
          wordpress_parent
        }
      }
    }
  `);
  const {
    title: caseStudyTitle,
    slug,
    content,
    excerpt,
    featuredImage,
    link,
    case_study_service: caseStudyServices,
  } = item;
  const parentServices = allCaseStudyServices.nodes.filter(service => {
    return caseStudyServices.includes(service.wordpress_id) && service.wordpress_parent === 0;
  });
  const subServices = allCaseStudyServices.nodes.filter(service => {
    return caseStudyServices.includes(service.wordpress_id) && service.wordpress_parent !== 0;
  });
  return (
    <Link to={link} className="feed-item" key={slug}>
      <div className="feed-item-image">
        <Image image={featuredImage} />
      </div>
      <div className="feed-item-details">
        {/* {(parentServices.length > 0 || subServices.length > 0) && (
          <div className="services">
            <span className="parent-services">
              {decodeEntities(parentServices.map(s=>s.name).join(', '))}
            </span>
            <span className="sub-services">
              {decodeEntities(subServices.map(s=>s.name).join(', '))}
            </span>
          </div>
        )} */}
        <h2
          dangerouslySetInnerHTML={{__html: caseStudyTitle}}
          className="feed-item-heading"
        />
        <p
          dangerouslySetInnerHTML={{__html: getExcerpt(content, 100)}}
          className="feed-item-excerpt"
        />
      </div>
    </Link>
  );
}

export default class CaseStudiesList extends Component {
  render() {
    const {
      caseStudies,
      range,
      perPage,
      pathPrefix,
      pageContext,
      location
    } = this.props;
    return (
      <section className="post-feed case-studies-list">
        <div className="wrapper">
          {caseStudies && (
            <div className="feed-items">
              {!range && !perPage ?
                // Show all products
                caseStudies.map((caseStudy, index) =>
                  <ListItem key={index} item={caseStudy} index={index} />
                ) :
                // Show products in range
                caseStudies.map((caseStudy, index) => (
                  ( index >= (range - perPage) && index < range ) && <ListItem key={index} item={caseStudy} index={index} />
                ))
              }
            </div>
          )}
          <Pagination
            pageContext={pageContext}
            pathPrefix={pathPrefix}
            showCounts
            location={location}
          />
        </div>
      </section>
    )
  }
}
