import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getUrlVars } from '../../utils/helpers';
import CaseStudiesList from './CaseStudiesList';
import Pagination from '../Pagination';

export const CaseStudiesFilter = ({ location, caseStudies }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          paginate
        }
      }
      caseStudies: allWordpressWpCaseStudy(filter: { slug: { ne: "gatsby-build-case-study" } }) {
        nodes {
          link
          slug
          title
          content
          excerpt
          case_study_service
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        pageInfo {
          pageCount
          perPage
          itemCount
          currentPage
        }
      }
      allWordpressWpCaseStudyService {
        nodes {
          slug
          wordpress_id
        }
      }
    }
  `);

  const queryParams = getUrlVars();
  let filterCaseStudies = caseStudies || data.caseStudies.nodes;

  if (queryParams && queryParams.filter) {
    const filterId = data.allWordpressWpCaseStudyService.nodes.find(service => {
      return service.slug === queryParams.filter;
    }).wordpress_id;
    filterCaseStudies = filterCaseStudies.filter(caseStudy => {
      return caseStudy.case_study_service.includes(filterId);
    });
  }

  const paged = queryParams.page ? queryParams.page : null;
  const itemCount = filterCaseStudies ? filterCaseStudies.length : 0;
  const perPage = data.site.siteMetadata.paginate;
  const numberOfPages = Math.ceil(itemCount / perPage);
  const nextPage = paged ? ( parseInt(paged) + 1 ) : 2;
  const prevPage = paged ? parseInt(paged) - 1 : null;
  const range = paged ? perPage * paged : perPage;
  const pageContext = {
    previousPagePath: prevPage,
    nextPagePath: nextPage <= numberOfPages ? nextPage : null,
    numberOfPages
  };
  return (
    <div className="products-filter-container">
      <CaseStudiesList
        caseStudies={filterCaseStudies}
        range={range}
        perPage={perPage}
      />
      <Pagination
        pageContext={pageContext}
        pathPrefix="/pivotal-projects/"
        location={location}
      />
    </div>
  );
}
