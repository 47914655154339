import React from 'react';
import { navigate } from 'gatsby';
import { decodeEntities } from '../../utils/htmlParse';
import { compileQuery } from '../../utils/helpers';
import './ServiceFilters.scss';

const mainServiceNavigateTo = (event, caseStudiesSlug) => {
  return navigate(`${event.target.value ? `/${caseStudiesSlug}/${event.target.value}/` : `/${caseStudiesSlug}/`}`)
}
const subServiceNavigateTo = (event, pathPrefix) => {
  return navigate(`${pathPrefix}${event.target.value ? `${compileQuery('filter', event.target.value, 'page')}` : ''}`)
}

const ServiceFilters = ({ pathPrefix, mainServices, subServices, activeFilter = null, caseStudiesSlug }) => (
  <div className="service-filters">
    <div className="wrapper">
      <div className="filters-container">

        <div className="service-filter">
          <span className="filter-label">Filter by service</span>
          <form>
            <div className="form-inner">
              <div>
                <select className="filter-select" onChange={(e) => mainServiceNavigateTo(e, caseStudiesSlug)}>
                  <option value="">All Services</option>
                  {mainServices.map((mainService, index) => {
                    return (
                      <option
                        key={index}
                        value={mainService.slug}
                        selected={activeFilter && activeFilter === mainService.slug}
                      >
                        {decodeEntities(mainService.name)}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>

        <div className="service-filter">
          <span className="filter-label">Sub service</span>
          <form>
            <div className="form-inner">
              <div>
                <select className="filter-select" onChange={(e) => subServiceNavigateTo(e, pathPrefix)}>
                  <option value="">All Sub Services</option>
                  {subServices.map((subService, index) => {
                    return (
                      <option key={index} value={subService.slug}>
                        {decodeEntities(subService.name)}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

);

export default ServiceFilters;
