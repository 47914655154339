import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { CaseStudiesFilter } from '../components/CaseStudies/CaseStudiesFilter';
import ServiceFilters from '../components/CaseStudies/ServiceFilters';
import { TextHeader } from '../acf/TextHeader/TextHeader.jsx';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { decodeEntities } from '../utils/htmlParse';
import './casestudies.scss';

const getAllViewableServiceElements = (caseStudies, serviceNodes) => {
  const allViewableServiceIds = caseStudies.reduce((acc, caseStudy) => {
    const { case_study_service: services } = caseStudy;
    const distinctServiceIds = new Set(acc.concat(services));
    return Array.from(distinctServiceIds);
  }, []);
  const allViewableServiceElements = serviceNodes.nodes.filter(service => {
    return allViewableServiceIds.indexOf(service.wordpress_id) !== -1;
  });
  return allViewableServiceElements;
}

export default class CaseStudiesMainServiceFilterPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { site, allWordpressWpCaseStudy, caseStudiesPageComponents, allWordpressWpCaseStudyService } = data;
    if (!allWordpressWpCaseStudy || !caseStudiesPageComponents) return null;
    const {
      childWordPressAcfTextHeader = null,
      childWordPressAcfFormBlock = null,
    } = caseStudiesPageComponents;
    const {
      title: siteTitle,
      caseStudiesSlug
    } = site.siteMetadata;
    const { nodes: caseStudies } = allWordpressWpCaseStudy;
    const allViewableServiceElements = getAllViewableServiceElements(caseStudies, allWordpressWpCaseStudyService);
    const subServices = allViewableServiceElements.filter(el => el.wordpress_parent !== 0);
    const viewableCaseStudies = caseStudies.filter((caseStudy) => {
      const isViewable = caseStudy.case_study_service && caseStudy.case_study_service.some(id => {
        return allWordpressWpCaseStudyService.nodes.findIndex(service => service.wordpress_id === id) !== -1;
      });
      return isViewable;
    });
    return (
      <Layout location={location} className="case-studies-template">
        <SEO title={`Blog | ${decodeEntities(siteTitle)}`} />
        {childWordPressAcfTextHeader && (
          <TextHeader
            smallHeading={childWordPressAcfTextHeader.smallHeading}
            heading={childWordPressAcfTextHeader.heading}
          />
        )}
        {/* <ServiceFilters
          caseStudiesSlug={caseStudiesSlug}
          activeFilter={pageContext.serviceSlug}
          pathPrefix={`/${caseStudiesSlug}/${pageContext.serviceSlug}/`}
          mainServices={pageContext.mainServiceFilters}
          subServices={subServices}
        /> */}
        <CaseStudiesFilter
          caseStudies={viewableCaseStudies}
          location={location}
        />
        {childWordPressAcfFormBlock && (
          <FormBlock
            smallHeading={childWordPressAcfFormBlock.smallHeading}
            heading={childWordPressAcfFormBlock.heading}
            form={childWordPressAcfFormBlock.form}
            location={location}
          />
        )}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CaseStudiesFilterQuery($limit: Int!, $skip: Int!, $wordpressId: Int!) {
    site {
      siteMetadata {
        title
        caseStudiesSlug
      }
    }
    caseStudiesPageComponents: wordpressPage(slug: { eq: "pivotal-projects" }) {
      childWordPressAcfTextHeader {
        smallHeading
        heading
        subHeading
      }
      childWordPressAcfFormBlock {
        smallHeading
        heading
        form
      }
    }
    allWordpressWpCaseStudy(
      sort: { fields: date, order: DESC }
      filter: { case_study_service: {in: [$wordpressId]} }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        featuredImage: featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        content
        date(formatString: "MMMM DD, YYYY")
        slug
        link
        case_study_service
      }
    }
    allWordpressWpCaseStudyService {
      nodes {
        name
        slug
        wordpress_id
        wordpress_parent
      }
    }
  }
`
